import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

const nocontent = {
  minHeight: "150px",
};

export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      footerlinks: [],
    };
  }

  componentDidMount() {
    const fetchURL = "/data/mainnav-menu.json";
    fetch(fetchURL)
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          footerlinks: response,
        });
      });
  }

  render() {
    return (
      <section
        style={{ backgroundColor: "#272727" }}
        className="pt-5 d-none d-lg-block"
        id="footer"
      >
        <Container>
          <Row>
            <Col md="3" id="footer-privacy">
              <img
                src="/images/pldt-corpwebsite-footer-logo.png"
                alt="pldt-logo"
                className="mx-auto d-block img-fluid"
              />
              <div className="d-flex my-4 justify-content-between">
                <a
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/pldt"
                  target="_blank"
                >
                  <img
                    src="/images/pldt-corpwebsite-footer-facebook.png"
                    alt="facebook-logo"
                    className="mx-auto d-block img-fluid"
                  />
                </a>
                <a
                  rel="noopener noreferrer"
                  href="https://twitter.com/PLDT"
                  target="_blank"
                >
                  <img
                    src="/images/pldt-corpwebsite-footer-twitter.png"
                    alt="twitter-logo"
                    className="mx-auto d-block img-fluid"
                  />{" "}
                </a>
                <a
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/pldt/"
                  target="_blank"
                >
                  <img
                    src="/images/pldt-corpwebsite-footer-instagram.png"
                    alt="instagram-logo"
                    className="mx-auto d-block img-fluid"
                  />
                </a>
                <a
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/company/pldt/"
                  target="_blank"
                >
                  <img
                    src="/images/pldt-corpwebsite-footer-linkedin.png"
                    alt="linkedin-logo"
                    className="mx-auto d-block img-fluid"
                  />
                </a>
                <a
                  rel="noopener noreferrer"
                  href="https://www.youtube.com/channel/UCLqsGKdRVcAAEdiemuF_oDg"
                  target="_blank"
                >
                  <img
                    src="/images/pldt-corpwebsite-footer-youtube.png"
                    alt="youtube-logo"
                    className="mx-auto d-block img-fluid"
                  />
                </a>
              </div>
              <div className="d-flex justify-content-between align-items-start">
                <Link to="/privacy-policy">
                  <p>Privacy Policy</p>
                </Link>
                <p className="mx-1">|</p>
                <Link to="/terms-of-use">
                  <p>Terms of Use</p>
                </Link>
              </div>
              <p>© 2024 PLDT Inc.</p>
            </Col>
            <Col md="6 pb-5">
              <Row>
                {this.state.footerlinks.map((links) => {
                  return (
                    <Col
                      md="4"
                      className="mb-3"
                      key={links.title}
                      style={nocontent}
                    >
                      {links.type === "ext" ? (
                        <a
                          href={links.titlelink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <h5 className="mb-3 mt-2">{links.title}</h5>
                        </a>
                      ) : (
                        <Link to={links.titlelink}>
                          <h5 className="mb-3 mt-2">{links.title}</h5>
                        </Link>
                      )}
                      {links.content.map((data) => {
                        return (
                          <Link to={data.link} key={data.text}>
                            <p>{data.text}</p>
                          </Link>
                        );
                      })}
                    </Col>
                  );
                })}
              </Row>
            </Col>
            <Col md="3">
              <div className="text-white position-absolute" id="footer-redbg">
                <h5 className="text-white">PLDT CORPORATE OFFICE</h5>
                <div className="d-flex align-items-start">
                  <img
                    src="/images/pldt-corpwebsite-footer-location.png"
                    alt="footer-location"
                    className="mr-3"
                  />
                  <p className="text-white">
                    Ramon Cojuangco Building, Makati Ave. corner Ayala Ave.,
                    Legaspi Village, Makati City, Metro Manila, Philippines 1200
                  </p>
                </div>
                <div className="d-flex align-items-start">
                  <img
                    src="/images/pldt-corpwebsite-footer-telephone.png"
                    alt="footer-telephone"
                    className="mr-3"
                  />
                  <p className="text-white">
                    For customers: 171
                    <br />
                    or +63 (2) 8 8888 171
                    <br />
                    For investors: +63 (2) 8816-8024​
                    <br />
                    For media: +63 (2) 8816-8684
                  </p>
                </div>
                <div className="d-flex align-items-start">
                  <img
                    src="/images/pldt-corpwebsite-footer-fax.png"
                    alt="footer-fax"
                    className="mr-3"
                  />
                  <p className="text-white">+63 (2) 8840-1864​</p>
                </div>
                <div className="d-flex align-items-start">
                  <img
                    src="/images/pldt-corpwebsite-footer-email.png"
                    alt="footer-email"
                    className="mr-3"
                  />
                  <a href="mailto:media@pldt.com.ph">
                    <p className="text-white">media@pldt.com.ph</p>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}
